@import "styles/styles.scss";
.title__byline {
  margin-top: 8px;
  text-align: center;

  @include tablet-up {
    max-width: 614px;
    margin-left: auto;
    margin-right: auto;
  }

  .title__byline_small {
    color: $color-terciary-light;
    margin-top: 25px;
    text-align: left;

    a {
      color: $color-terciary;
    }
  }
}
