@import "styles/styles.scss";
.returnsForm__element {
  margin-bottom: 24px;
  padding-right: 10px;
  padding-left: 10px;
  flex: auto;
  width: 100%;

  &.inline {
    width: auto;
    min-width: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.returnsForm__label {
  margin-bottom: 10px;
  text-align: left;
  color: $color-primary;
  display: block;
  letter-spacing: 0;
  text-transform: none;

  :first-child {
    margin-top: 0;
  }

  &.returnsForm__label_title {
    @include subtitleMedium(true);

    margin-bottom: 20px;
  }
}