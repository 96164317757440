@import "styles/styles.scss";
.dropdown {
  @include bodyCopyRegular(true);

  overflow: initial;
  height: 50px;
  position: relative;

  ::-webkit-scrollbar {
    appearance: none;
    width: 6px;
    height: 84px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-terciary;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .dropdown__container {
    display: flex;
    width: 100%;
    background-color: $color-neutral-min;
    border: 1px solid $color-neutral-medium;
    transition: border-color 0.3s $ease-in-out;
    align-items: center;
    padding: 13px 40px 13px 18px;

    &:hover {
      border-color: $color-terciary-extra-light;
    }

    &:focus-visible {
      outline: none;
      border-color: $color-primary;
    }

    &.isOpen {
      transition: none;
      border-color: $color-primary;
    }
  }

  .dropdown__list {
    @extend %list-no-style;

    border: 1px solid $color-primary;
    border-top: none;
    overflow-x: hidden;
    overflow-y: overlay;
    max-height: 250px;
    position: relative;
    background-color: $color-neutral-min;
    z-index: 5;

    &[data-hide="true"] {
      max-height: 0;
      visibility: hidden;
      border-color: transparent;
    }
  }

  .dropdown__selected {
    cursor: pointer;
    text-align: left;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  select {
    @include bodyCopyRegular(true);

    color: $color-terciary;
    appearance: none;
    border-radius: 0;
    background-color: $color-neutral-min;
    margin: 0;
    border: 1px solid $color-neutral-medium;
    position: relative;
    cursor: pointer;
    padding: 13px 40px 13px 18px;
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    transition: border-color 0.3s $ease-in-out;

    &:focus-visible {
      border-color: $color-primary;
      outline: none;
    }
  }

  .dropdown__valueNotAvailable {
    color: $color-terciary-extra-light;
  }

  .arrowDown {
    pointer-events: none;
    position: absolute;
    right: 15px;
    transform: translateY(-50%);
    top: 50%;
  }
}
