@import "styles/styles.scss";
.title__wrapper {
  @include titleSmall;
  
  text-align: center;

  @include tablet-up {
    text-align: left;
  }

  @include desktop-up {
    @include titleMedium;
  }

  &:global(.title) {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  &:global(.title__medium--bottom) {
    margin-bottom: 25px;
  }

  &:focus {
    color: $color-primary;
    text-decoration: none;

    &::after {
      background: $color-primary;
    }
  }

  &:global(.title--light) {
    color: $color-neutral-min;
  }

  &:global(.title--small) {
    @include titleSmall;
  }

  // Position of children
  &:global(.title--left) {
    text-align: left;
  }

  &:global(.title--center) {
    @extend %center;

    text-align: center;
  }

  &:global(.title--left-mobile) {
    text-align: left;
  }

  // // New type
  &:global(.title--secondary) {
    @include subtitleMedium(true);
  }

  &:global(.title--secondary-small) {
    @include subtitleSmall(true);
  }

  a {
    color: $color-primary;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-primary;
      text-decoration: none;

      &::after {
        background: $color-primary;
      }
    }
  }
}
