@import "styles/styles.scss";
.SwatchColor {
  border-radius: 50%;
  border: 1px solid transparent;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  position: relative;

  &:hover {
    border-color: $color-terciary-light;
  }

  &::after {
    border-radius: 50%;
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 39px;
    height: 39px;
    background-image: var(--swatch-background-image);
    background-color: var(--swatch-background-color);
    background-size: cover;
  }

  &.SwatchColor_plain {
    &::after {
      border: 1px solid $color-terciary-extra-light;
    }
  }

  &.SwatchColor_selected {
    border-color: $color-primary;
  }

  &.size_small {
    width: 40px;
    height: 40px;

    &::after {
      height: 32px;
      width: 32px;
    }
  }

  &.isUnavailable {
    &::before {
      content: "";
      background-color: $color-neutral-medium;
      display: block;
      top: 50%;
      left: 5px;
      transform: rotate(-45deg);
      width: 39px;
      height: 1px;
      position: absolute;
      z-index: 1;
    }

    &.size_small {
      &::before {
        width: 33px;
        left: 3px;
      }
    }
  }

  span {
    color: transparent;
    font-size: 0;
  }
}
