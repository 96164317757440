@import "styles/styles.scss";
.ReturnsHeader {
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 50px 10px;
  width: 100%;

  @include tablet-up {
    padding-top: 70px;
    padding-bottom: 60px;
  }

  h1,
  p {
    color: $color-neutral-min;
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin-top: 8px;

    @include desktop-up {
      margin-top: 15px;
    }
  }
}
