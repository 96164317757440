@import "styles/styles.scss";
.image__container {
  align-items: center;
  background: $color-neutral-medium;
  display: flex;
  margin: 0 auto;
  height: auto;
  min-height: 185px;
  overflow: hidden;
  position: relative;
  max-width: 130px;
  width: 100%;

  @include tablet-up {
    max-width: 165px;
  }

  img {
    display: block;
    width: 100%;
  }
}
