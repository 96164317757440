@import "styles/styles.scss";
.product {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid $color-neutral-medium;
  transition: border 0.3s $ease-in-out;

  a:hover,
  a:focus {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    border-color: $color-terciary-extra-light;
  }

  .imagePlaceholder {
    background-color: $color-neutral-light;
    height: 0;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &[data-status="unavailable"] {
    display: none;
  }

  .product__primaryImage {
    opacity: 1;
    transition: opacity 0.2s $ease-in;
    left: 0;
    position: absolute;
    top: 0;

    @include tablet-up {
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0;
      }
    }
  }

  .product__imageContainer {
    @include placeholder-ratio(400, 600);

    overflow: hidden;

    @include tablet-up {
      @include placeholder-ratio(400, 400);
    }
  }

  .product__price,
  .product__fullPrice,
  .product__color {
    @include bodyCopyRegular(true);

    margin-top: 1px;
  }

  .product__color {
    color: $color-terciary;
    text-align: center;
    margin-right: 5px;
  }

  .product__image {
    margin-bottom: 13px;
    position: relative;
    width: 100%;
  }

  .product__secondaryImage {
    margin-bottom: 10px;
  }

  .product__prices {
    @include bodyCopyBold;

    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex: 1;
  }

  .product__fullPrice {
    @include bodyCopyBold;

    color: $color-terciary-extra-light;
    text-decoration: line-through;
    margin-right: 5px;
  }
}

.ProductDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}
