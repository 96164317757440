@mixin titleDefaults($includeDefaults: false) {
  @include fontFamilySecondary;
  
  @if $includeDefaults {
    color: $color-primary;
    font-weight: 400;
  }
}

@mixin titleBold() {
  @include fontFamilySecondaryBold;
}

@mixin titleSmallX4($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 18px;
  letter-spacing: -0.3px;
  line-height: 21px;
}

@mixin titleSmallX3($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 22px;
  letter-spacing: -0.3px;
  line-height: 25px;
}

@mixin titleSmallX2($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 26px;
  letter-spacing: -0.3px;
  line-height: 29px;
}

@mixin titleSmallX1($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 30px;
  letter-spacing: -0.3px;
  line-height: 33px;
}

@mixin titleSmall($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 35px;
  letter-spacing: -0.5px;
  line-height: 40px;
}

@mixin titleMedium($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 40px;
  letter-spacing: -0.5px;
  line-height: 43px;
}

@mixin titleLarge($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 45px;
  letter-spacing: -0.5px;
  line-height: 48px;
}

@mixin titleLargeX1($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 55px;
  letter-spacing: -0.5px;
  line-height: 55px;
}

@mixin titleLargeX2($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 65px;
  letter-spacing: -1.5px;
  line-height: 63px;
}

@mixin titleLargeX3($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 75px;
  letter-spacing: -1.5px;
  line-height: 72px;
}

@mixin titleLargeX4($includeDefaults: false) {
  @include titleDefaults($includeDefaults);

  font-size: 100px;
  letter-spacing: -1.5px;
  line-height: 110px;
}
