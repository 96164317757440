@import "styles/styles.scss";
.ReviewItem {
  background-color: $color-neutral-min;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.ReviewItem__copy {
  padding-right: 25px;
}

.ReviewItem__title {
  @include titleSmallX2(true);

  font-style: italic;
  font-weight: 500;
  margin: 0 0 15px;

}

.ReviewItem__description {
  @include titleSmallX3(true);

  line-height: 26px;
  font-weight: 400;
  font-style: italic;
  flex: 1;
}

.ReviewItem__author {
  @include bodyCopySmall;
  @include bodyCopyBold;

  display: flex;
  flex-direction: column;
  margin: 35px 0 0;
  color: $color-primary;

  p {
    margin-top: 10px;
  }
}

.ReviewItem__button {
  margin-top: 40px;
  max-width: fit-content;
}