@import "styles/styles.scss";
.loader {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loader_flex {
    min-height: 200px;
    height: 100%;
  }
}
