@import "styles/styles.scss";
.checkboxGroup {
  border: 1px solid $color-neutral-medium;
}

.checkbox {
  display: flex;
  padding: 14px 18px;
  position: relative;
  background-color: $color-neutral-min;

  &:not(&:last-child) {
    border-bottom: 1px solid $color-neutral-medium;
  }

  .checkbox__labelText {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: "";
      display: block;
      background-color: $color-neutral-min;
      border: 1px solid $color-neutral-medium;
      height: 28px;
      width: 28px;
      transition-property: background-color, border-color;
      transition-duration: 0.3s;
      transition-timing-function: $ease-in-out;
    }
  }

  .checkbox__inputTick {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      &:hover,
      &:focus {
        fill: $color-neutral-min;

        g {
          stroke: $color-neutral-min;
        }
      }
    }
  }

  .checkbox__input {
    position: absolute;
    visibility: hidden;
    opacity: 0;

    &:focus {
      outline: none;

      .checkbox__labelText::after {
        border: 1px solid $color-primary;
      }
    }
  }

  .checkbox__input:checked {
    + .checkbox__labelText {
      &::after {
        background-color: $color-primary;
        border-color: $color-primary;
      }

      .checkbox__inputTick {
        visibility: visible;
      }
    }
  }
}
