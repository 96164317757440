@import "styles/styles.scss";
.Reviews {
  background-color: $color-neutral-min;
  padding-bottom: 50px;

  @include tablet-up {
    padding-bottom: 60px;
  }

  @include desktop-up {
    padding-bottom: 80px;
  }

  &__wrapper {
    position: relative;
    padding: 0 24px;
    margin: 50px auto 0;

    @include tablet-up {
      max-width: 90%;
    }

    @include desktop-up {
      padding: 0 0 0 35px;
    }
    
    @include desktopX1-up {
      width: 1175px;
    }
  }
}

.QuoteMarks {
  margin-bottom: 35px;
  width: 56px;

  @include tablet-up {
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 7px;
    z-index: 10;
  }

  @include desktop-up {
    width: 103px; 
  }
}

.sectionHeader {
  padding-right: 20px;
  padding-left: 20px;
}

.Reviews__carousel {
  margin: 0 auto;

  @include tablet-up {
    max-width: 80%;
  }

  @include desktop-up {
    max-width: 75%;
  }

  @include desktopX2-up {
    max-width: 926px;
  }
}

.Reviews__carouselContainer {
  width: calc(100% + 4em);
  padding: 0 2em;
  margin-left: -2em;
}