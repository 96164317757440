@import "styles/styles.scss";
.returnsBanner__content {
  align-items: center;
  background-color: $color-primary;
  bottom: -100%;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 0 51px;
  height: auto;
  position: fixed;
  right: 0;
  transition: all 0.5s ease-in-out 0.3s;
  z-index: 1000000;
  
  &:last-child {
    padding: 5px 30px 30px;
  }

  @include tablet-up {
    flex-direction: row;
    height: 104px;

    &:last-child {
      padding: 0 51px;
    }
  }

  &.active {
    bottom: 0;
  }
}

.returnsBanner__button {
  display: flex;
  justify-content: center;
  width: 100%;

  @include tablet-up {
    justify-content: flex-start;
    margin-left: 60px;
    width: 50%;
  }
}

.returnsBanner__details {
  @include tablet-up {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }

  .returnsBanner__details__text {
    width: fit-content;
  }

  .returnsBanner__details__byline {
    color: $color-neutral-light;
    text-align: left;
    margin: 18px 0 14px;

    @include tablet-up {
      margin: 0;
    }

    .returnsBanner__details__byline_small {
      @include bodyCopySmall;
    }
  }
}

.returnsBanner__byline {
  color: $color-neutral-light;
  margin: 18px 0 14px;

  @include tablet-up {
    margin: 0;
  }
}

.returnsBanner__title {
  @include subtitleMedium(true);

  color: $color-neutral-light;
  display: none;

  @include tablet-up {
    display: block;
  }
}
