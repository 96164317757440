@mixin fontFamilyPrimary {
  font-family: var(--font-dmsans),ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

@mixin fontFamilySecondary {
  font-family: var(--font-madigan), Georgia, serif;
}


@mixin fontFamilySecondaryBold {
  font-family: 'Madigan SemiBold', Georgia, serif;
}
