/** Mobile **/

@mixin mobile-down {
  @media (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin mobile-small-down {
  @media (max-width: #{$breakpoint-mobile-small}) {
    @content;
  }
}

/** Tablet **/

@mixin tablet-up {
  @media (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin tablet-large-up {
  @media (min-width: #{$breakpoint-tablet-large}) {
    @content;
  }
}

@mixin tablet-large-down {
  @media (max-width: #{$breakpoint-tablet-large - 1px}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop  - 1px}) {
    @content;
  }
}

@mixin tablet-only-portrait {
  @media only screen
  and (min-width: #{$breakpoint-tablet})
  and (orientation: portrait)
  and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin tablet-only-landspace {
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
  @media only screen
  and (min-width: #{$breakpoint-desktop})
  and (orientation: landscape)
  and (max-width: #{$breakpoint-desktopX1})
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin tablet-pro-only-portrait {
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
  @media only screen
    and (min-width: #{$breakpoint-desktop})
    and (orientation: portrait)
    and (max-width: #{$breakpoint-desktopX2})
    and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/** Desktop **/

@mixin desktop-up {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin desktopX1-up {
  @media (min-width: #{$breakpoint-desktopX1}) {
    @content;
  }
}

@mixin desktopX2-up {
  @media (min-width: #{$breakpoint-desktopX2}) {
    @content;
  }
}

@mixin desktopX3-up {
  @media (min-width: #{$breakpoint-desktopX3}) {
    @content;
  }
}
