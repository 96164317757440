@mixin bodyCopyDefaults($includeDefaults: false) {
  @include fontFamilyPrimary;
  
  @if $includeDefaults {
    font-weight: 400;
    letter-spacing: unset;
  }
}

@mixin bodyCopyLightBold {
  font-weight: 400;
}

@mixin bodyCopyBold {
  font-weight: 600;
}

@mixin bodyCopySmallX2($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 10px;
  line-height: 13px;
}

@mixin bodyCopySmallX($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 12px;
  line-height: 16px;
}

@mixin bodyCopySmall($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 14px;
  line-height: 21px;
}

@mixin bodyCopyRegular($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 16px;
  line-height: 22px;
}

@mixin bodyCopyLarge($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 18px;
  line-height: 24px;
}

@mixin bodyCopyLargeX1($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 20px;
  line-height: 26px;
}

@mixin bodyCopyLargeX2($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 22px;
  line-height: 28px;
}

@mixin bodyCopyLargeX3($includeDefaults: false) {
  @include bodyCopyDefaults($includeDefaults);

  font-size: 24px;
  line-height: 30px;
}
