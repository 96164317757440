@import "styles/styles.scss";
.ProductHeader__container {
  margin: 15px 0;
}

.ProductHeader__title {
  margin-bottom: 2px;
}

.ProductHeader__byline {
  color: $color-terciary-light;
}

.ProductHeader__prices {
  display: flex;
  justify-content: center;

  @include tablet-up {
    justify-content: flex-start;
  }
}

.ProductHeader__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  height: 25px;
}
