@import "styles/styles.scss";
.sizeChart__table {
  border-collapse: collapse;
  width: 100%;
  display: none;

  @include tablet-up {
    display: table;
  }
}

.sizeChart__table_international {
  overflow-x: auto;
  margin-bottom: 40px;
  width: 100%;
}

.sizeChart__table_international table {
  border-collapse: collapse;
  width: 100%;
}

.sizeChart__row {
  background-color: $color-neutral-light;

  &:nth-child(1) {
    background-color: $color-neutral-medium;
    color: $color-primary;
  }
}

.sizeChart__rowOdd {
  background-color: $color-neutral-min;
}

.sizeChart__cell {
  @include bodyCopySmallX(true);

  border-collapse: collapse;
  height: 48px;
  line-height: 24px;
  min-width: 40px;
  text-align: center;

  @include tablet-up {
    @include bodyCopySmall;
  }

  &:first-child {
    padding-left: 14px;
    text-align: left;
    min-width: 40px;
    width: 250px;

    @include tablet-up {
      padding-left: 25px;
    }
  }

  &:last-child {
    padding-right: 14px;
    text-align: right;

    @include tablet-up {
      padding: inherit;
      text-align: center;
    }
  }
}

.sizeChart__row_title {
  th {
    @include bodyCopySmallX;

    background-color: $color-neutral-medium;
    color: $color-primary;
    font-weight: normal;
    padding-left: 14px;
    text-align: left;

    @include tablet-up {
      padding-left: 25px;
    }
  }
}
