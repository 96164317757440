@import "styles/styles.scss";
.list__option__button {
  cursor: pointer;
  padding: 14px 20px;
  width: 100%;
  text-align: left;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    background-color: $color-neutral-light;
  }

  &:not(.list__option_notAvailable) {
    &.selected,
    &.selected:hover {
      background-color: $color-primary;
      color: $color-neutral-min;
    }
  }

  &.list__option_notAvailable {
    color: $color-terciary-extra-light;
    background-color: $color-neutral-light;
    cursor: not-allowed;
    pointer-events: none;
  }
}
