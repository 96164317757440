/** Inputs **/
$input-height-regular: 45px;
$input-height-large: 60px;

/** header **/
$flashbanner-height-mobile: 35px;
$flashbanner-height-desktop: 40px;
$header-height-regular: 50px;
$header-height-large: 70px;
$header-desktop-padding: 18px;

/** Animations **/
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$easing-header: cubic-bezier(0, 0.96, 1, 1);

/** Responsive **/
// Mobile Small < 350px
// Tablet > 600px
// Desktop > 1000px
// DesktopX1 > 1280px
// DesktopX2 > 1600px
// DesktopX3 > 2500px
$max-width-small: 1000px;
$max-width: 1250px;
$max-width-large: 1440px;

$breakpoint-mobile-small: 350px;
$breakpoint-mobile: 366px;
$breakpoint-large-mobile: 406px;
$breakpoint-tablet: 600px;
$breakpoint-tablet-large: 768px;
$breakpoint-tablet-pro: 1024px;
$breakpoint-desktop: 1024px;
$breakpoint-desktopX1: 1280px;
$breakpoint-desktopX2: 1512px;
$breakpoint-desktopX3: 2500px;

/** Colours **/
$color-primary: #001e3c;
$color-primary-medium: #0e2846;
$color-primary-light: #183250;
$color-primary-extra-light: #4b6a8e;

$color-secondary: #F05A23;

$color-terciary: #475161;
$color-terciary-light: #949fac;
$color-terciary-extra-light: #c6c9cc;

$color-neutral-max: #000;
$color-neutral-medium: #eaeaea;
$color-neutral-light: #f7f8f8;
$color-neutral-min: #fff;

$color-success: #2fa06a;
$color-warning: #e1953c;
$color-danger: #cd324e;
