@mixin subtitleDefaults($includeDefaults: false) {
  @include fontFamilyPrimary;
  
  @if $includeDefaults {
    font-weight: 500;
    color: $color-primary;
    letter-spacing: unset;
  }
}

@mixin subtitleXSmall($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 12px;
  line-height: 16px;
}

@mixin subtitleSmall($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 14px;
  line-height: 19px;
}

@mixin subtitleMedium($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 16px;
  line-height: 22px;
}

@mixin subtitleLarge($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 18px;
  line-height: 24px;
}

@mixin subtitleLargeX1($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 20px;
  line-height: 26px;
}

@mixin subtitleLargeX2($includeDefaults: false) {
  @include subtitleDefaults($includeDefaults);

  font-size: 22px;
  line-height: 28px;
}
