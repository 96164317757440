@import "styles/styles.scss";
.ProductCard {
  position: relative;
}

.ProductCard__imageWrapper {
  position: relative;
  background-color: $color-neutral-light;

  img {
    width: 283px;
    display: block;
  }

  .ProductCard__secondaryImage {
    transition: opacity 0.2s $ease-in;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    top: 0;
    left: 0;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  :global(.lazyload-placeholder) {
    width: 283px;
  }
}

.ProductCard__badgeWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  > div {
    display: flex;
  }
}
