@import "styles/styles.scss";
.PriceComparison__fullPrice,
.PriceComparison__price {
  @include bodyCopyRegular;

  &.small,
  &.small-bold {
    @include bodyCopySmallX;
  }

  &.medium-small-bold,
  &.medium-bold,
  &.small-bold {
    font-weight: 500;
  }

  &.medium-small-bold,
  &.medium-small {
    @include bodyCopySmall;
  }

  &.reverse {
    order: 1;
    margin-right: 12px;
  }
}

.PriceComparison__fullPrice {
  color: $color-terciary-light;
  text-decoration: line-through;

  &.reverse {
    order: 2;
  }
}

.PriceComparison__price {
  color: $color-primary;
}

.PriceComparison__priceReduced {
  color: $color-danger;
}

.PriceComparison__fullPrice + .PriceComparison__price {
  margin-left: 6px;

  &.small,
  &.small-bold {
    margin-left: 9px;
  }

  &.medium {
    margin-left: 15px;
  }
}
