@import "styles/styles.scss";
.modalProductCard {
  background-color: $color-neutral-min;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include tablet-large-up {
    flex-basis: 50%;
  }
}

.modalProductCard__cardDetailsWrapper {
  @include tablet-large-down {
    justify-content: flex-start;
  }
}

.modalProductCard__cardDetails__fullWidth {
  @include tablet-up {
    max-width: 100%;
  }
}

.modalProductCard__image {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.modalProductCard__product__detailsDesktop {
  width: 100%;
  max-width: 100%;
  padding: 25px 20% 40px;
}

.modalProductCard__fullWidthDesktop {
  max-width: 100%;
}

.modalProductCard__productCardHeader__centerAlign {
  justify-content: center;
}
