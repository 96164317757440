@import "styles/styles.scss";

.ColorStep__colorsContainer {
  position: relative;
  padding-bottom: 45px;
}

.ColorStep__colorFooter {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ColorStep__colorsList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 2px;
  margin: 0 -5px;

  @include desktop-up {
    column-gap: 5px;
    row-gap: 5px;
  }
}

.ColorStep__colorLink {
  @include subtitleMedium(true);

  color: $color-primary;
  text-decoration: underline;
}

.ColorStep__colorTitle {
  @include bodyCopyRegular;

  text-align: left;
}