@import "styles/styles.scss";
.toggle {
  display: flex;
  align-items: center;

  .toggle__label {
    color: $color-primary;
  }

  .toggle__button {
    background-color: $color-neutral-min;
    border-radius: 20px;
    border: 1px solid $color-neutral-min;
    height: 40px;
    margin: 0 15px 3px;
    outline: none;
    position: relative;
    width: 70px;

    &:hover,
    &:focus {
      border-color: $color-neutral-medium;
    }

    &::after {
      background-color: $color-primary;
      border-radius: 50%;
      content: "";
      height: 28px;
      left: 6px;
      position: absolute;
      top: 5px;
      transition: transform 0.3s $ease-in-out;
      width: 28px;
    }

    &[data-toggle-value="1"] {
      &::after {
        transform: translateX(30px);
      }
    }
  }
}
