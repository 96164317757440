@import "styles/styles.scss";
.productProperties {
  .productProperties__content {
    display: inline-flex;
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    p {
      @include bodyCopySmall;

      text-transform: capitalize;

      @include tablet-up {
        font-size: 16px;
      }
    }
  }

  .productProperties__newValue {
    color: $color-terciary-light;
    text-decoration: line-through;
  }

  .productProperties__values {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .productProperties__name {
    color: $color-terciary;
  }
}
