@import "styles/styles.scss";
.sizeChart__tabs {
  outline: 1px solid $color-terciary-extra-light;
  display: flex;
  flex-direction: column;
  margin: 0 14px;

  @include tablet-up {
    outline: 1px solid $color-primary;
    flex-direction: row;
    margin: 0;
    width: calc(100% - 2px);
  }

  &.typeTabs {
    display: flex;
    max-width: 500px;
    width: 100%;
  }

  &.desktopTypeTabs {
    margin-bottom: 45px;
  }
}

.sizeChart__tab {
  @include bodyCopySmallX;

  background-color: $color-neutral-min;
  color: $color-primary;
  flex-grow: 1;
  outline: 1px solid $color-terciary-extra-light;
  height: 50px;
  position: relative;
  padding: 0 20px;
  width: 100%;
  border: none;
  text-align: left;

  @include tablet-up {
    @include bodyCopySmall(true);

    padding: inherit;
    text-align: center;
  }

  &[data-selected="true"] {
    background-color: $color-primary;
    outline: 1px solid $color-primary;
    color: $color-neutral-min;
    overflow: visible !important;

    @include tablet-up {
      &::before {
        content: "";
        border-top: 5px solid $color-primary;
        border-right: 8px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid transparent;
        height: 0;
        left: 50%;
        margin-left: -8px;
        position: absolute;
        top: 100%;
        width: 0;
      }
    }
  }

  &:not(:last-child) {
    &::after {
      background-color: $color-terciary-extra-light;
      color: $color-neutral-min;
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 100%;

      @include tablet-up {
        background-color: $color-primary;
        width: 1px;
        height: 100%;
      }
    }
  }
}
