@import "styles/styles.scss";
.returnsForm__container {
  width: 100%;

  @include tablet-up {
    min-height: 380px;
  }

  @include tablet-large-up {
    padding: 0;
    max-width: 350px;
  }
}

.returnsForm__stepTitle {
  @include subtitleMedium(true);

  margin-bottom: 20px;
  text-align: left;
}