@import "styles/styles.scss";
$grid-spacing-small: 20px;
$grid-spacing-large: 32px;

$grid-padding-small: 20px;
$grid-padding-medium: 30px;
$grid-padding-large: 40px;

.productList__container {
  @extend %list-no-style;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $grid-spacing-small;
  row-gap: $grid-spacing-small;
  margin: 20px 0 40px;
  padding: 0 $grid-padding-small;

  @include tablet-up {
    padding-left: $grid-padding-small;
    padding-right: $grid-padding-small;
    padding: 0 $grid-padding-medium;
    max-width: $max-width + 80px;
  }

  @include desktop-up {
    grid-template-columns: repeat(4, 1fr);
    column-gap: $grid-spacing-large;
    margin: 30px 0 40px;
  }

  @include desktopX1-up {
    padding: 0;
  }
}
