@import "styles/styles.scss";
.productCardHeader {
  display: inline-flex;
  gap: 5px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;

  @include tablet-up {
    border-bottom: 1px solid $color-neutral-medium;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 18px;
  }

  > p {
    @include subtitleMedium(true);

    flex: none;
    text-align: left;
  }
}
