@import "styles/styles.scss";
.sizeChart__table {
  display: none;
  border-collapse: collapse;
  width: 100%;

  @include tablet-up {
    display: table;
  }
}

.sizeChart__tableWrapper {
  overflow-x: auto;
  width: 100%;
}

.sizeChart__row {
  &:nth-child(odd) {
    background-color: $color-neutral-light;
  }

  &:nth-child(1) {
    background-color: $color-neutral-medium;
    color: $color-primary;
  }

  &:nth-child(even) {
    background-color: $color-neutral-min;

    .sizeChart__table:not(.sizeChart__table--standard-row) & {
      background-color: $color-neutral-min;
    }
  }
}

.sizeChart__cell {
  @include bodyCopySmallX(true);

  border-collapse: collapse;
  height: 48px;
  line-height: 24px;
  min-width: 40px;
  text-align: center;

  @include tablet-up {
    @include bodyCopySmall;
  }

  &:first-child {
    padding-left: 14px;
    text-align: left;
    width: 250px;

    @include tablet-up {
      padding-left: 25px;
    }
  }
}

.sizeChart__row_title {
  th {
    @include bodyCopySmallX;

    background-color: $color-neutral-medium;
    color: $color-primary;
    font-weight: normal;
    padding-left: 14px;
    text-align: left;

    @include tablet-up {
      padding-left: 25px;
    }
  }
}
