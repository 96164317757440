@import "styles/styles.scss";
.productCard {
  background: $color-neutral-min;
  min-height: 185px;
  width: 100%;

  @include tablet-up {
    min-width: 375px;
    max-width: 480px;
  }

  @include desktop-up {
    min-height: auto;
  }

  @include desktopX1-up {
    min-width: 440px;
  }

  &__container {
    display: flex;
  }

  &__price {
    display: flex;
    align-items: flex-end;
    margin-top: 7px;

    @include tablet-up {
      margin-top: 0;
    }
  }
}

.productContent {
  padding: 20px 30px;
  width: 100%;
}
