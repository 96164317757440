@import "styles/styles.scss";
.ReturnExchangeNotification {
  border: 1px solid $color-neutral-medium;
  background-color: $color-neutral-min;
  margin-top: 20px;
  padding: 25px 30px;
  text-align: center;

  h3 {
    @include subtitleSmall(true);

    margin-bottom: 5px;
  }

  p {
    @include bodyCopySmallX();

    color: $color-terciary;
    margin-bottom: 20px;
  }
}

.ReturnsSavedFitBox {
  background-color: $color-neutral-light;
  color: $color-primary;
  padding: 18px;
  margin: 15px auto;
  text-align: center;
  width: 100%;

  &__title {
    @include subtitleSmall(true);

    margin-bottom: 10px;
  }

  &__options {
    display: flex;
    justify-content: center;
  }

  &__option {
    @include bodyCopySmall();

    color: $color-terciary;

    &:not(:last-of-type) {
      &::after {
        content: "|";
        margin-left: 7px;
        margin-right: 7px;
      }
    }
  }

  &__optionType {
    margin-right: 5px;
    text-transform: capitalize;
  }

  &__optionValue {
    @include bodyCopyBold();
  }
}
