@import "styles/styles.scss";
.byline {
  transition: color 0.5s ease-in-out;

  @include subtitleDefaults(true);

  // Theme style
  &:global(.byline--secondary) {
    @include bodyCopyRegular;

    color: $color-terciary;
  }

  &:global(.byline--primary) {
    @include subtitleMedium(true);

    color: $color-terciary;
  }

  // Theme Colour
  &:global(.byline--theme-light-grey) {
    color: $color-terciary-light;
  }

  &:global(.byline--theme-light) {
    color: $color-neutral-min;
  }

  // Theme size
  &:global(.byline--small-x1-size) {
    @include subtitleSmall;
  }

  &:global(.byline--small-size) {
    @include subtitleMedium;
  }

  &:global(.byline--medium-size) {
    @include subtitleLarge; // @todo class name and subtitle type mismatch, makes it confusing
  }

  &:global(.byline--large-size) {
    @include subtitleLargeX1;
  }

  // Theme position
  &:global(.byline--text-left) {
    text-align: left;
  }

  &:global(.byline--text-center) {
    text-align: center;
  }
}
