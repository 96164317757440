@import "styles/styles.scss";
.starRating {
  display: flex;
}

.totalReview {
  @include bodyCopySmall;

  line-height: unset;
}
