@import "styles/styles.scss";

.returnsForm__buttonWrapper {
  display: flex;
  margin-top: 35px;
  width: 100%;

  button { 
    margin: 0;
    width: auto;

    &:first-of-type {
      margin-right: 20px;
      max-width: 80px;
    }
  }
}