@import "styles/styles.scss";
$desktop-products-spacing: 30px;
$mobile-products-spacing: 20px;

.ProductsSlider__products {
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 $mobile-products-spacing;
  width: 100%;
  
  @extend %list-no-style;

  @include desktop-up {
    grid-column-gap: $desktop-products-spacing + 10;
  }

  &.center {
    @include desktop-up {
      justify-content: center;
    }
  }

  &.thinDivider {
    @include desktop-up {
      grid-column-gap: $mobile-products-spacing;
    }
  }

  li {
    position: relative;

    &:first-child {
      margin-left: $mobile-products-spacing;
    }

    &:last-child {
      margin-right: $mobile-products-spacing;
    }

    @include tablet-up {
      &:first-child {
        margin-left: $desktop-products-spacing;
      }

      &:last-child {
        margin-right: 28px;
      }
    }

    .ProductsSlider__productLink {
      text-decoration: none;
    }
  }
}
